<template>
  <div
    class="custom-confirmed-btn pointer"
    :class="status ? 'red-confirmed' : 'green-confirmed'"
    @click.stop="updateConfirmation"
  >
    <label class="text-center mb-0 text-btn pointer">
      {{ status ? $t('general.actions.no') : $t('general.actions.yes') }}
    </label>
  </div>
</template>
<script>
import {_put} from "@api/doinsport/services/httpService";

export default {
  data: () => ({
    isBusy: false,
  }),
  props: {
    participant: {
      type: Object,
      default: this,
    }
  },
  computed: {
    status() {
      return this.participant.confirmedParticipation === null || !this.participant.confirmedParticipation;
    }
  },
  methods: {
    updateConfirmation() {
      let confirmed = null !== this.participant.confirmedParticipation ? !this.participant.confirmedParticipation : true;

      if (!this.isBusy) {
        this.isBusy = true;

        _put(this.participant.iriParticipant, {confirmed: confirmed})
          .then((response) => {
            this.participant.confirmedParticipation = response.data.confirmed;
            this.$bus.$emit('on:participant-confirmation:update', this.participant);
          })
          .finally(() => {
            this.isBusy = false;
          })
        ;
      }
    }
  },
}
</script>
<style lang="scss" src="@lazy/planning/_confirmed-participant-btn.scss" scoped/>
